import React from "react";

import Layout from "../components/Layout";
import Seo from "../components/seo";

import AlexImage from "../images/alexandre-debieve-FO7JIlwjOtU-unsplash.jpg";
import LucaImage from "../images/luca-bravo-2-min.jpg";
import LarsImage from "../images/lars-kienle-IlxX7xnbRF8-unsplash.jpg";
import ChrisImage from "../images/christopher-gower-m_HRfLhgABo-unsplash.jpg";

const IndexPage = () => (
  <Layout>
    <Seo
      title="Home"
      description="Superbase Web Shop Home Page - Buy Superbase NG Products"
    />
    <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="0"
          className=""
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="1"
          aria-label="Slide 2"
          className=""
        ></button>
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="2"
          aria-label="Slide 3"
          className="active"
          aria-current="true"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item">
          <img src={AlexImage} alt="" style={{ opacity: "30%" }} />
          <div className="container">
            <div className="carousel-caption text-start">
              <h1>Free for Non-commercial.</h1>
              <p>
                Superbase NG Professional is completely free for non-commercial
                projects
              </p>
              <div className="left">
                <a className="button-yellow" href="/account">
                  Sign Up Today
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img src={LucaImage} alt="" style={{ opacity: "30%" }} />
          <div className="container">
            <div className="carousel-caption">
              <h1>Professional Development Suite</h1>
              <p>
                Everything required to build and debug Cross-Platform
                Applications
              </p>
              <div className="div center">
                <a className="button-yellow" href="/products/superbase-ng-ide">
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item active">
          <img src={LarsImage} alt="" style={{ opacity: "30%" }} />

          <div className="container">
            <div className="carousel-caption text-end">
              <h1>Rapid Application Development</h1>
              <p>
                Create Forms, Databases and Reports all in one simple GUI
                interface.
              </p>
              <div className="right">
                <a
                  className="button-yellow"
                  href="/products/superbase-ng-personal#gallery"
                >
                  Browse Gallery
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>

    {/* <!-- Marketing messaging and featurettes
    ================================================== -->
    <!-- Wrap the rest of the page in another container to center all the content. --> */}

    <div className="container marketing">
      {/* <!-- Three columns of text below the carousel --> */}
      <div className="row">
        <div className="col-lg-4">
          <img
            className="img-fluid d-none d-sm"
            src="https://www.superbase.com/wp-content/uploads/2019/07/cropped-sbnglogo_694x694x16m.jpg"
            alt="Superbase NG Logo"
            width="100"
            height="140"
            border-radius="50%"
          />

          <h2>Superbase NG Professional</h2>
          <p>
            All the components required for building and debugging web server
            applications, pure server programs and desktop applications.
          </p>
          <div className="left">
            <a className="button-orange" href="/products/superbase-ng-ide">
              View Details
            </a>
          </div>
        </div>
        <div className="col-lg-4">
          <img
            className="img-fluid d-none d-sm"
            src="https://www.superbase.com/wp-content/uploads/2019/07/cropped-sbnglogo_694x694x16m.jpg"
            alt="Superbase NG Personal Logo"
            width="100"
            height="140"
            border-radius="50%"
          />

          <h2>Superbase NG Personal</h2>
          <p>
            Create Forms, Databases and Reports all in one simple, easy-to-use,
            GUI interface.
          </p>
          <div className="left">
            <a className="button-orange" href="/products/superbase-ng-personal">
              View Details
            </a>
          </div>
        </div>
        <div className="col-lg-4">
          <img
            className="img-fluid d-none d-sm"
            src="https://www.superbase.com/wp-content/uploads/2019/07/cropped-sbnglogo_694x694x16m.jpg"
            alt="Superbase Server Logo"
            width="100"
            height="100"
            border-radius="50%"
          />

          <h2>Superbase Server</h2>
          <p>
            The Superbase NG Multi-User Database Engine. A powerful, high-speed
            database engine that can run on both Windows and Linux.
          </p>
          <div className="left">
            <a className="button-orange" href="/products/server">
              View Details
            </a>
          </div>
        </div>
      </div>

      {/* <!-- START THE FEATURETTES --> */}

      <hr className="featurette-divider" />

      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading">
            Works with you{" "}
            <span className="text-muted">
              as well as your existing workflow
            </span>
          </h2>
          <p className="lead">
            Integrate Superbase NG into your existing workflow, and API's with
            ease. We provide components to provide secure web communications
          </p>
        </div>
        <div className="col-md-5">
          <img
            className="featurette-image mx-auto cover"
            objectposition="20% 20%"
            src={ChrisImage}
            alt="Superbase Server Logo"
            width="500px"
            height="500px"
            focusable="false"
          />
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="row featurette">
        <div className="col-md-7 order-md-2">
          <h2 className="featurette-heading">
            Rapid Development{" "}
            <span className="text-muted">out of the box.</span>
          </h2>
          <p className="lead">
            With our custom built Rapid Development tool Superbase Personal It
            is easy as 1, 2, 3 to create new forms and reports and rapidly
            develop ready to deploy code.
          </p>
        </div>
        <div className="col-md-5 order-md-1">
          <svg
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
            width="500"
            height="500"
            xmlns="http://www.w3.org/2000/svg"
            aria-label="Placeholder: 500x500"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
          >
            <title>Placeholder</title>
            <rect width="100%" height="100%" fill="#eee"></rect>
            <text x="50%" y="50%" fill="#aaa" dy=".3em">
              500x500
            </text>
          </svg>
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading">
            Scales to your needs.{" "}
            <span className="text-muted">
              Store and retrieve millions of records.
            </span>
          </h2>
          <p className="lead">
            Our Superbase NG Database Engine is designed to be fully compatible
            with Superbase Classic and can store hundreds of thousands of
            records all ready to be retrieved at a moments notice
          </p>
        </div>
        <div className="col-md-5">
          <svg
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
            width="500"
            height="500"
            xmlns="http://www.w3.org/2000/svg"
            aria-label="Placeholder: 500x500"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
          >
            <title>Placeholder</title>
            <rect width="100%" height="100%" fill="#eee"></rect>
            <text x="50%" y="50%" fill="#aaa" dy=".3em">
              500x500
            </text>
          </svg>
        </div>
      </div>

      <hr className="featurette-divider" />
    </div>
  </Layout>
);

export default IndexPage;
